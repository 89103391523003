.jssocials-share-twitter .jssocials-share-link {
  background: #00aced;
  border: 1px solid #0099d4; }
  .jssocials-share-twitter .jssocials-share-link:hover {
    background: #0087ba; }

.jssocials-share-facebook .jssocials-share-link {
  background: #3b5998;
  border: 1px solid #344e86; }
  .jssocials-share-facebook .jssocials-share-link:hover {
    background: #2d4373; }

.jssocials-share-googleplus .jssocials-share-link {
  background: #dd4b39;
  border: 1px solid #d73925; }
  .jssocials-share-googleplus .jssocials-share-link:hover {
    background: #c23321; }

.jssocials-share-linkedin .jssocials-share-link {
  background: #007bb6;
  border: 1px solid #006a9d; }
  .jssocials-share-linkedin .jssocials-share-link:hover {
    background: #005983; }

.jssocials-share-pinterest .jssocials-share-link {
  background: #cb2027;
  border: 1px solid #b51d23; }
  .jssocials-share-pinterest .jssocials-share-link:hover {
    background: #9f191f; }

.jssocials-share-email .jssocials-share-link {
  background: #3490F3;
  border: 1px solid #1c83f2; }
  .jssocials-share-email .jssocials-share-link:hover {
    background: #0e76e6; }

.jssocials-share-stumbleupon .jssocials-share-link {
  background: #eb4823;
  border: 1px solid #e03a14; }
  .jssocials-share-stumbleupon .jssocials-share-link:hover {
    background: #c93412; }

.jssocials-share-whatsapp .jssocials-share-link {
  background: #29a628;
  border: 1px solid #249123; }
  .jssocials-share-whatsapp .jssocials-share-link:hover {
    background: #1f7d1e; }

.jssocials-share-telegram .jssocials-share-link {
  background: #2ca5e0;
  border: 1px solid #1f98d3; }
  .jssocials-share-telegram .jssocials-share-link:hover {
    background: #1c88bd; }

.jssocials-share-line .jssocials-share-link {
  background: #25af00;
  border: 1px solid #209600; }
  .jssocials-share-line .jssocials-share-link:hover {
    background: #1a7c00; }

.jssocials-share-viber .jssocials-share-link {
  background: #7b519d;
  border: 1px solid #6e488c; }
  .jssocials-share-viber .jssocials-share-link:hover {
    background: #61407b; }

.jssocials-share-pocket .jssocials-share-link {
  background: #ef4056;
  border: 1px solid #ed2841; }
  .jssocials-share-pocket .jssocials-share-link:hover {
    background: #e9132e; }

.jssocials-share-messenger .jssocials-share-link {
  background: #0084ff;
  border: 1px solid #0077e6; }
  .jssocials-share-messenger .jssocials-share-link:hover {
    background: #006acc; }

.jssocials-share-vkontakte .jssocials-share-link {
  background: #45668e;
  border: 1px solid #3d5a7d; }
  .jssocials-share-vkontakte .jssocials-share-link:hover {
    background: #344d6c; }

.jssocials-share-link {
  padding: .5em .6em;
  border-radius: 0.3em;
  box-shadow: inset 0 1px 3px rgba(255, 255, 255, 0.3), 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.15);
  color: #fff;
  -webkit-transition: background 200ms ease-in-out;
          transition: background 200ms ease-in-out; }
  .jssocials-share-link:hover, .jssocials-share-link:focus, .jssocials-share-link:active {
    color: #fff; }

.jssocials-share-count-box {
  position: relative;
  height: 2.5em;
  padding: 0 .3em;
  margin-left: 6px;
  border-radius: 0.3em;
  border: 1px solid #dcdcdc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15); }
  .jssocials-share-count-box:before {
    content: "";
    position: absolute;
    top: 1em;
    left: -9px;
    display: block;
    width: 0;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: #dcdcdc transparent;
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg); }
  .jssocials-share-count-box:after {
    content: "";
    position: absolute;
    top: 1em;
    left: -7px;
    display: block;
    width: 0;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #fff transparent;
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg); }
  .jssocials-share-count-box .jssocials-share-count {
    line-height: 2.75em;
    color: #444;
    font-size: .9em; }
